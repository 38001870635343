import { FC, memo } from 'react'

import { Text, Icon } from '@cash-me/react-components'
import NextImage from "next/image"

import { Grid, Col } from 'components/grid'

import * as S from './styles'

const Renovation: FC = memo(() => (
  <S.Section $bg="medium">
    <Grid col={{ phone: 1, tablet: 1 }}>
      <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
        <S.Content>
          <S.ImageWrapper>
            <NextImage
              src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/condo/portaria-eletronica.png?updated=03072022`}
              alt="Portaria eletrônica"
              width="767"
              height="512"
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          </S.ImageWrapper>

          <S.Infos>
            <Text as="h3" size="heading3" weight="medium">
              Obras e Reformas
            </Text>

            <Text>Utilize o empréstimo para:</Text>

            <S.List>
              <S.ListItem>
                <S.ListItemIcon>
                  <Icon name="elevator" color="var(--colors-cyan-icon-default)" />
                </S.ListItemIcon>
                <S.ListItemText>Modernização de elevador</S.ListItemText>
              </S.ListItem>

              <S.ListItem>
                <S.ListItemIcon>
                  <Icon name="garage" color="var(--colors-cyan-icon-default)" />
                </S.ListItemIcon>
                <S.ListItemText>Cobertura de garagem</S.ListItemText>
              </S.ListItem>

              <S.ListItem>
                <S.ListItemIcon>
                  <Icon name="waterdrop" color="var(--colors-cyan-icon-default)" />
                </S.ListItemIcon>
                <S.ListItemText>Individualização de água e gás</S.ListItemText>
              </S.ListItem>
            </S.List>
          </S.Infos>
        </S.Content>
      </Col>
    </Grid>
  </S.Section>
))

Renovation.displayName = 'Renovation'

export default Renovation
